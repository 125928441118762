<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-sm table-bordered text-center" id="purchase_order_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Product</th>
              <th>Transfer Qty</th>
              <th>Accepted <span style="color: red">|Dispute Qty</span></th>
              <th>Transfer Comment</th>
              <th>Hub Comment</th>
              <th>Transfer Date</th>
              <th>Status</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(transfer, index) in transfers" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ transfer.free_purchase_request_item.name }}</td>
              <td>{{ transfer.transfer_amount }}</td>
              <td>{{ remainingAmount(transfer.transfer_amount, transfer.dispute_amount)}} <span style="color: red">| {{ transfer.dispute_amount || 0 }}</span></td>
              <td>{{ transfer.transferer_comment }}</td>
              <td>{{ transfer.hub_comment }}</td>
              <td>{{ customDate(transfer.created_at) }}</td>
              <td>
                <a-tag color="#f50" v-if="transfer.transfer_status == 'Pending'">Pending</a-tag>
                <a-tag color="#f50" v-if="transfer.transfer_status == 'Rejected'">Rejected</a-tag>
                <a-tag color="#87d068" v-if="transfer.transfer_status == 'Accepted'">Accepted</a-tag>
                <a-tag color="#E0A800" v-if="transfer.transfer_status == 'Partial'">Partial</a-tag>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="transfers.length === 0">
            <Empty :image="simpleImage"></Empty>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'

export default {
  name: 'List',
  components: { Empty },
  data() {
    return {
      transfers: {},
      requisition: {},
      loading: false,
      spinning: false,
      delayTime: 500,
      current: 0,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getTransferRequisitionItems()
  },
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
    getTransferRequisitionItems() {
      this.loading = true
      this.spinning = true
      apiClient.get('api/hub/free/product-previous-transfer/search')
        .then(response => {
          this.loading = false
          this.spinning = false
          if (!response.data.error) {
            const data = response.data
            this.transfers = data.transfers
          } else {
            this.$notification.error({
              message: response.data.message,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    remainingAmount(transferAmount, disputeAmount) {
      return parseFloat(transferAmount) - parseFloat(disputeAmount | 0)
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
